import './LandingPage.css'
import React from 'react';
function Header() {
    return (
      <div className='container'>
        <div className ="headerDiv">
          <img id = "profimg" src = "/Q2.png" alt="Logo" height="100px">
          </img>
          <h1 id = "proftxt">ueueTea.xyz</h1>
        </div>
        <div className='headerBackground'>
        </div>
        <div className= "animatedBackground">
          <div className="checkerboard">
          </div>
        </div>
      </div>
    );
  }
  
export default Header;