import React from 'react';
import './LandingPage.css'
function AboutPage() {
    return (
      <div className='container'>
        <div className ="aboutDiv">

        <div className='textBoxes'>
            <img className='sparkles' src ="/cloud1.png" alt = "" style={{left: "400px", top:"100px", animationDelay:"2.5s"}}></img>
            <img className='sparkles' src ="/cloud2.png" alt = "" style={{left: "20px", top:"500px", animationDelay:".75s"}}></img>
            <img className='sparkles' src ="/cloud3.png" alt = "" style={{left: "600px", top:"300px", animationDelay:".3s"}}></img>
            <div className='pictureFrame'>
              <div className='textblock'>
                <h1>Welcome to my website</h1>
                <p>this website was made by: Quinton Thuet</p>
                <p>last updated: 6/29/2024</p>
                <img src = "doodle.png" alt = ""></img>
              </div>
              <img className = 'frame' alt="" src='/Window2.png'></img>
            </div>
            <div className='pictureFrame' style={{zIndex: "5", left:"300px", top:"300px"}}>
              <div className='textblock'>
                <h1>About me:</h1>
                <h3>Current occupation:</h3>
                <p>software dev at shelter insurance</p>
                <h3>Graduated:</h3>
                <p>Mizzou 2023 bachelors of computer science</p>
              </div>
              <img className = 'frame' alt="" src='/Window2.png'></img>
            </div>
          </div>

          <div className='pictureBoard'>
            <img className='sparkles' src ="/sparkles.png" alt = "" style={{right: "400px", top:"100px", animationDelay:".75s"}}></img>
            <img className='sparkles' src ="/sparkles.png" alt = "" style={{right: "-100px", top:"650px", animationDelay:"1.5s"}}></img>
            <img className='sparkles' src ="/sparkles.png" alt = "" style={{left: "200px", top:"275px"}}></img>
            <div className='pictureFrameB' style={{transform:"rotate(-15deg)", top: "40px"}}>
                <img className='pin' src ="/pin.png" alt = "" style={{right:"170px", top:"0px"}}></img>
                <img className ='frameB'src = "/polaroid.png" alt=""></img>
                <img className = 'pictureB' src = "/TheBoys.jpg"  style={{transform: "translateY(16px)"}} alt="PersonalPhoto2"></img>
            </div>
            <div className='pictureFrame' style={{left: "30px", top: "425px", transform:"rotate(30deg)"}}>
                <img className='pin' src ="/pin2.png" alt = "" style={{left:"0px", top:"0px"}}></img>
                <img className = 'picture' src = "/Q+T.jpg" alt="PersonalPhoto" height="300px" style={{transform: "translateY(-90px) translateX(-5px)"}}></img>
                <img className = 'frame' alt="" src='/polaroid.png'></img>
            </div>
            <div className='pictureFrame'style={{right: "50px", top: "40px", transform:"rotate(15deg)"}}>
                <img className='pin' src ="/pin4.png" alt = "" style={{left:"20px", top:"20px"}}></img>
                <img className = 'picture' src = "/nicePhoto.jpg" alt="PersonalPhoto2" height="300px" style={{transform: "translateX(-15px)"}}></img>
                <img className = 'frame' alt="" src='/polaroid.png'></img>
            </div>
            <div className='pictureFrame'style={{right: "30px", top: "500px", transform:"rotate(-15deg)"}}>
                <img className='pin' src ="/pin3.png" alt = "" style={{right:"20px", top:"0px"}}></img>
                <img className = 'picture'  src = "/VeryNicePhoto.jpg" alt="PersonalPhoto2" height="300px" style={{transform: "translateY(-80px) translateX(-10px)"}}></img>
                <img className = 'frame' alt="" src='/polaroid.png'></img>
            </div>
          </div>
          
        </div>
      </div>
    );
  }

export default AboutPage;