import Header from "./Header";
import Navbar from "./Navbar";
import PageContent from "./PageContent";
import React, { useState } from 'react';

function LandingPage() {

  const [currentPage, setCurrentPage] = useState('About')
  function handlePageChange(page) {setCurrentPage(page)};

  return (
    <div className="LandingPage">
      <Header/>
      <Navbar pageChange ={handlePageChange}/>
      <PageContent page={currentPage}></PageContent>
    </div>
  );
}

export default LandingPage;
