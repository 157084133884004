import React from 'react';
import AboutPage from './AboutPage'
import Guestbook from './Guestbook';


function PageContent({page}) {
  switch (page) {
    case "About":
      return <AboutPage />;
    case "Guestbook":
      return <Guestbook />;
    default:
      return <AboutPage />;
  }
}
  
export default PageContent;