import React from 'react';
import './LandingPage.css'

function Navbar({pageChange}) {
    return (
        <div className='navbarContainer'>
          <div className = "navbarDiv">
            <div className='navButton' onClick={() => pageChange('About')}>
              <p className="noselect navText">AboutPage</p>
            </div>
            <div className='navButton' onClick={() => pageChange('Guestbook')}>
            <p className="noselect navText">Guestbook</p>
            </div>
          </div>
          <div className='navbarBackground'>
          </div>
        </div>
    );
  }
  
export default Navbar;